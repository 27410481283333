import {GetRenderer} from "./renderer.js";
import { worker } from "./worker-service.js";
import { Email, AuthToken } from "./auth.js";

import {toReference} from "flatbuffers/js/flexbuffers/reference.js";
import {Builder} from "flatbuffers/js/flexbuffers/builder.js";

import ReconnectingWebSocket from 'reconnecting-websocket';

import {RecordingSession, PlaybackFromFile, InMessage, OutMessage} from './replay-stream.js'; 

import {Pointers} from './pointers.js';

export const EventStreamDisconnected = 'streamdisconnected';
export const EventStreamConnected = 'streamconnected';
export const EventStreamConnecting = 'streamconnecting';
export const EventIDRWaiting = 'evtIDRWait';
export const EventIDRReceived = 'evtIDRRecv';


let _videoScale = Number(window.localStorage.getItem("request-video-scale")||"1.0");
document.addEventListener("RequestVideoScaleChanged",event=>{
	_videoScale = event.detail.scale;
});

let recordingSession = null;

window.addEventListener('keydown',event=>{
	if (event.key=='F2'){
		if (recordingSession == null){
			recordingSession = new RecordingSession();
			recordingSession.StartRecording();
		}else{
			recordingSession.StopRecording();
			recordingSession.Save(selectedWorkload);
		}
	}

	if (event.key=='F3'){
		PlaybackFromFile();
	}
})

class Impl {
	constructor(){
		this.websocket = null;
		this.framesSub = null;
		this.usersdataSub = null;

		this.source = null;
		this.waitingIDR = true;
		this.forceIDR = false;
		this.requestIDRGate = null;
		this.textDecoder = new TextDecoder('utf-8');

		// #SetupCanvas
		const canvas = document.createElement('canvas');
		const offscreen = canvas.transferControlToOffscreen();
		const rendererName = GetRenderer();
		worker.postMessage({type : "start", rendererName, canvas: offscreen}, [offscreen]);

		this.canvas = ()=>canvas;

		this.canvasroot = null;
		this.containerRect = null;
		this.canvassizeObserver =  null;

		// Mouse state
		this.mouseX = 0;
		this.mouseY = 0;
		this.mouseWheelDeltaX = 0;
		this.mouseWheelDeltaY = 0;
		this.mouseButtons = 0;

		this.touchClickSimulation = 0; // 1 - started, 2 - started-ended, 3 - ended
		this.touchClickSimulationTicker = []; // 1 - started, 2 - started-ended, 3 - ended, 4 - half step of started

		this.pressedKeyCodes = {}
		this.inputchars = []

		this.instanceID = Math.round(Math.random()*(1<<30));
		this.username = null;

		this.pointers = null;
	}

	setup(source, canvasroot){

		if (typeof source == 'undefined' ||
			typeof canvasroot == 'undefined') {
			throw('source or canvasroot are not defined');
		}

		this.source = source;
		this.username = Email() || "anon";

		
		if (typeof canvasroot=="string") {
			this.canvasroot = document.querySelector(canvasroot);
		}else if ('appendChild' in canvasroot) {
			this.canvasroot = canvasroot
		}

		if (!this.canvasroot){
			throw('canvas root not found');
		}

		this.canvasroot.appendChild(this.canvas());

		this.pointers = new Pointers();

		const freezedCanvasRoot = this.canvasroot;

		const canvassizeObserver = new ResizeObserver(nodes=>{
			this.updateContainerRect(freezedCanvasRoot);
		});

		// #Canvas Input Events. Mouse and Keyboard
		const onkeydown = (event)=>{this.onKeyDownHandler(event);}
		const onkeyup = (event)=>{this.onKeyUpHandler(event);}

		const onmousedown = event=>{this.saveCursorPos(event);this.mouseButtons = event.buttons;}
		const onmouseup = event=>{this.saveCursorPos(event);this.mouseButtons = event.buttons;}
		const onmousemove = event=>{this.saveCursorPos(event);}
		const oncontextmenu = event=>{this.mouseButtons = event.buttons;event.preventDefault()}
		const onwheel = event=>{this.mouseWheelDeltaX-=event.deltaX*0.01;this.mouseWheelDeltaY-=event.deltaY*0.01;event.preventDefault();}
		const workerCallback = event=>{this.handleWorkerMessage(event);}

		const ontouchstart = event=>{this.saveCursorPos(event.changedTouches[0]); if (this.touchClickSimulation === 0) this.touchClickSimulation = 1;}
		const ontouchend = event=>{this.saveCursorPos(event.changedTouches[0]); if (this.touchClickSimulation === 0) this.touchClickSimulation = 3; else this.touchClickSimulation = 2; }
		const ontouchcancel = event=>{if (this.touchClickSimulation === 0) this.touchClickSimulation = 3; else this.touchClickSimulation = 2;}
		const ontouchmove = event=>{this.saveCursorPos(event.changedTouches[0]);}

		const canvas = this.canvas();
		this.updateContainerRect(this.canvasroot);
		this.detachEventListener = ()=>{
			document.removeEventListener("keydown", onkeydown);
			document.removeEventListener("keyup", onkeyup);

			canvas.removeEventListener("mousemove",onmousemove);
			canvas.removeEventListener("mouseup",onmouseup);
			canvas.removeEventListener("mousedown",onmousedown);

			canvas.removeEventListener("contextmenu", oncontextmenu);
			canvas.removeEventListener("wheel", onwheel);

			canvas.removeEventListener("touchstart", ontouchstart);
			canvas.removeEventListener("touchend", ontouchend);
			canvas.removeEventListener("touchcancel", ontouchcancel);
			canvas.removeEventListener("touchmove", ontouchmove);

			worker.removeEventListener("message", workerCallback);

			canvassizeObserver.unobserve(freezedCanvasRoot);
		}

		this.attachEventListener = ()=>{
			document.addEventListener("keydown",onkeydown);
			document.addEventListener("keyup",onkeyup);

			canvas.addEventListener("mousemove", onmousemove);
			canvas.addEventListener("mouseup", onmouseup);
			canvas.addEventListener("mousedown", onmousedown);

			canvas.addEventListener("touchstart", ontouchstart, false);
			canvas.addEventListener("touchend", ontouchend, false);
			canvas.addEventListener("touchcancel", ontouchcancel, false);
			canvas.addEventListener("touchmove", ontouchmove, false);

			canvas.addEventListener("contextmenu", oncontextmenu);
			canvas.addEventListener("wheel",onwheel);

			worker.addEventListener("message", workerCallback);

			canvassizeObserver.observe(freezedCanvasRoot);
		}
	}

	Cleanup() {
		const canvas = this.canvas();

		if (canvas){
			canvas.parentNode.removeChild(canvas);
			this.canvas=()=>null;
		}
	}

	saveCursorPos(event){
		const rect = this.canvas().getBoundingClientRect();
		if (rect !== null) {
			this.mouseX = (event.clientX - rect.left) / (this.canvas().scale || 1);
			this.mouseY = (event.clientY - rect.top) / (this.canvas().scale || 1);
		}
	}

	StartListen(){
		this.attachEventListener();

		const userinfo = this.username;
		const instanceID = this.instanceID;

		const connectToStream = async(source)=>{
			document.dispatchEvent(new CustomEvent(EventStreamConnecting, {detail: {source}}));
			const connectResp = await fetch("/api/connect",{
				method: "POST", 
				body: JSON.stringify({source}),
				headers: {
					"Authorization": "Bearer "+AuthToken(),
				}
			})
			const connectJson = await connectResp.json();
			if ('error' in connectJson){
				console.error(respjson.error);
				window.dispatchEvent(new CustomEvent(EventStreamDisconnected,{detail: {source}}));
			}

			var serverUrl = connectJson.servers[connectJson.servers.length - 1];
			// TODO: Reenable for relay support
			// for (let server of connectJson.servers) {
			// 	const waitForOpen = new Promise((resolve, reject) => {
			// 		const socket = new WebSocket(server, [AuthToken()]);
			// 		socket.addEventListener("open", (event) => {
			// 			resolve(event);
			// 			socket.close();
			// 		});
			// 		socket.addEventListener("error", (event) => {
			// 			reject(event);
			// 			socket.close();
			// 		});
			// 	});
			//
			// 	try {
			// 		await waitForOpen;
			// 	} catch (e) {
			// 		console.error(e);
			// 		continue;
			// 	}
			// 	serverUrl = server;
			// 	break;
			// }
			const socket = new ReconnectingWebSocket(serverUrl, [AuthToken()]);

			let lastPacketNumber = 0;
			let lastVersion = null;
			const recentusers = [];

			socket.addEventListener("message", async (event) => {
				if (event.data instanceof Blob) {
					const canvas = this.canvas();

					const data = await event.data.arrayBuffer();

					// Skip flex
					if (recordingSession){
						recordingSession.Record(new InMessage(sub.subject, data));
					}

					const flexBuffRef = toReference(data.slice(4));
					const metaData = flexBuffRef.get(0);

					lastVersion = Number(metaData.get("version").intValue());
					const userInfoFB = metaData.get("userInfo");
					const packetNumber = Number(metaData.get("packetNumber").intValue());

					for (let i = 0; i < userInfoFB.length(); i++) {
						const userInfoRef = userInfoFB.get(i);
						const userId = Number(userInfoRef.get("uniqueId").intValue());
						const userName = userInfoRef.get("name").stringValue();
						const userViewOnly = userInfoRef.get("viewOnly").boolValue();
						recentusers.push(userId);

						const userEvt = new CustomEvent("b6-users-added",{detail: {
							id: userId,
							name: userName,
							viewOnly: userViewOnly,
						}});
						document.dispatchEvent(userEvt);

						if (userName !== this.username) {
							const mouseHistory = userInfoRef.get("mouseHistory");
							for (let j = 0; j < mouseHistory.length(); j++) {
								const mouse = mouseHistory.get(j);
								const x = mouse.get(1).floatValue();
								const y = mouse.get(2).floatValue();

								const pointer = this.pointers.CreatePointer(userId, userName);
								this.pointers.SetPointerLocation(userId, x, y, canvas.getBoundingClientRect(), canvas.scale);
							}
						}
					}

					const usersEvt = new CustomEvent("b6-users-updated",{detail: {users: recentusers}});
					document.dispatchEvent(usersEvt);
					this.pointers.KeepVisible(recentusers);

					const isIDR = metaData.get("isIDRFrame").boolValue()

					const videoWidth = Number(metaData.get("width").intValue());
					const videoHeight = Number(metaData.get("height").intValue());

					if (videoWidth != canvas.receivedVideoWidth ||
						videoHeight != canvas.receivedVideoHeight
					) {
						const videoContainerWidth = this.containerRect.width;
						const scale = Math.min(1, Math.min(this.containerRect.height/videoHeight, videoContainerWidth / videoWidth));
						const newW = Math.round(videoWidth*scale)+"px";
						const newH = Math.round(videoHeight*scale)+"px";


						canvas.receivedVideoWidth = videoWidth;
						canvas.receivedVideoHeight = videoHeight;

						canvas.style.width = newW;
						canvas.style.height = newH;

						canvas.scale = scale;
					}
					if (!this.waitingIDR || isIDR) {
						if (this.waitingIDR) {
							this.waitingIDR = false;
							document.dispatchEvent(new Event(EventIDRReceived));
						}

						const videoFrameBlob = flexBuffRef.get(1).blobValue();
						if (packetNumber !== (lastPacketNumber + 1) && !isIDR) {
							console.log("dropped frame. packetNumber", packetNumber, "lastPacketNumber", lastPacketNumber, "is IDR Frame?", isIDR);
							this.forceIDR = true;
						}
						lastPacketNumber = packetNumber;

						worker.postMessage({type: "data", data: videoFrameBlob}, [videoFrameBlob.buffer]);
					}
				}
			});

			this.websocket = socket;
			document.dispatchEvent(new CustomEvent(EventStreamConnected, {detail: {workload: source}}));

			this.userInputSendInterval = window.setInterval(()=>{
				if (lastVersion !== null) {
					this.sendRemoteInput(socket, source, userinfo, instanceID, lastVersion);
				}
			},30);
		}

		connectToStream(this.source);
	}

	remove(){
		const canvas = this.canvas();

		if (canvas&&canvas.parentNode){
			canvas.parentNode.removeChild(canvas);
			this.canvas = ()=>null;
		}
	}

	StopListen(){
		this.detachEventListener();

		// Clear Pointers
		this.pointers.ClearAll();

		if (this.framesSub){
			this.framesSub.unsubscribe();
			this.framesSub = null;
		}

		if (this.usersdataSub){
			this.usersdataSub.unsubscribe();
			this.usersdataSub = null;
		}

		if (this.websocket != null){
			this.websocket.close();
			this.websocket = null;
		}

		if (this.userInputSendInterval){
			window.clearInterval(this.userInputSendInterval);
			this.userInputSendInterval = null;
		}

		this.remove();
	}

	handleWorkerMessage(event){
		if (event.data=="kfr") {
			this.waitingIDR=true;
		}
	}

	GetButtonArray()
	{
		if (this.touchClickSimulation > 0) {
			// We got start-end in the same frame
			if (this.touchClickSimulation === 2) {
				this.touchClickSimulationTicker.push(1);
				this.touchClickSimulationTicker.push(3);
			} else {
				this.touchClickSimulationTicker.push(this.touchClickSimulation);
			}
			this.touchClickSimulation = 0;
		}

		if (this.touchClickSimulationTicker.length > 0) {
			let ticker = this.touchClickSimulationTicker[0];
			if (ticker === 1) {
				this.mouseButtons = 0;
				this.touchClickSimulationTicker[0] = 5;
			} else if (ticker > 4) {
				this.mouseButtons = 0;
				this.touchClickSimulationTicker[0] = this.touchClickSimulationTicker[0] - 1;
			} else if (ticker > 3) {
				this.mouseButtons = 1;
				this.touchClickSimulationTicker[0] = this.touchClickSimulationTicker[0] - 1;
				if (ticker === 4) {
					this.touchClickSimulationTicker.shift();
				}
			} else if (ticker === 3) {
				this.mouseButtons = 0;
				this.touchClickSimulationTicker.shift();
			}
		}

		return [Boolean(this.mouseButtons & 0x01),
			Boolean(this.mouseButtons>>1 & 0x01), Boolean(this.mouseButtons>>2 & 0x01),
			Boolean(this.mouseButtons>>3 & 0x01), Boolean(this.mouseButtons>>4 & 0x01)];
	}

	sendRemoteInput(websocket, source, userinfo, instanceID, version){

		if (websocket==null){
			return;
		}

		const scale = window.devicePixelRatio * (isFinite(_videoScale)?_videoScale:1.0);
		const rect = this.containerRect;

		const builder = new Builder();
		builder.startVector();

		builder.addInt(version);

		builder.addFloat(this.mouseX);
		builder.addFloat(this.mouseY);

		builder.startVector();
		const mouseButtons = this.GetButtonArray();
		for (let i = 0; i < mouseButtons.length; i++) {
			builder.add(mouseButtons[i]);
		}
		builder.end(false);

		builder.addFloat(this.mouseWheelDeltaY); // mouse wheel
		builder.addFloat(this.mouseWheelDeltaX); // mouse wheel h

		builder.add(true); // mouse on window

		let keyDownCount = 0;
		for (const key in this.pressedKeyCodes){
			if (this.pressedKeyCodes[key]) {
				keyDownCount++;
			}
		}
		const keysDownIndexes = new Uint16Array(keyDownCount);
		let i = 0;
		for (const key in this.pressedKeyCodes){
			if (this.pressedKeyCodes[key]) {
				keysDownIndexes[i++] = key;
			}
		}
		builder.add(keysDownIndexes);

		const analogValuesIndexes = new Uint16Array(0);
		builder.add(analogValuesIndexes);
		const analogValues = new Float32Array(0);
		builder.add(analogValues);

		const inputchars = new Uint16Array(this.inputchars.length);
		for (let j = 0; j < this.inputchars.length; j++){
			inputchars[j] = this.inputchars[j].charCodeAt(0);
		}
		builder.add(inputchars);

		const requestIDR = Boolean(this.waitingIDR || this.forceIDR);

		builder.add(requestIDR);
		builder.addInt(Math.floor(rect.width*scale)); //requestedWidth
		builder.addInt(Math.floor(rect.height*scale)); // requestedHeight

		builder.add(window.viewonly); // viewOnly1080

		builder.add(userinfo); // name

		builder.addUInt(instanceID); //uniqueId

		builder.end();

		const uint8 = builder.finish();

		var msg = new Uint8Array(4 + uint8.byteLength);
		var enc = new TextEncoder("ascii");
		msg.set(enc.encode("flex"), 0);
		msg.set(new Uint8Array(uint8), 4);

		if (requestIDR){
			if (this.requestIDRGate==null){
				this.requestIDRGate = setTimeout(()=>{
					this.requestIDRGate = null;
					if (this.waitingIDR || this.forceIDR) {
						document.dispatchEvent(new CustomEvent(EventIDRWaiting));
					}
				}, 1000)
			}
			console.log("Requesting IDR");
		}

		this.forceIDR = false;

		this.inputchars = [];

		this.mouseWheelDeltaY = 0;
		this.mouseWheelDeltaX = 0;

		if (recordingSession){
			recordingSession.Record(new OutMessage(selectedWorkload+".input", msg));
		}
		websocket.send(msg);
	}

	ToImGuiKey(ke) {
		let keyCode = ke.code;

		switch (keyCode) {
			case "Tab": return 512;
			case "ArrowLeft": return 513;
			case "ArrowRight": return 514;
			case "ArrowUp": return 515;
			case "ArrowDown": return 516;
			case "PageUp": return 517;
			case "PageDown": return 518;
			case "Home": return 519;
			case "End": return 520;
			case "Insert": return 521;
			case "Delete": return 522;
			case "Backspace": return 523;
			case "Space": return 524;
			case "Enter": return 525;
			case "Escape": return 526;
			case "Quote": return 584;
			case "Comma": return 585;
			case "Minus": return 586;
			case "Period": return 587;
			case "Slash": return 588;
			case "Semicolon": return 589;
			case "Equal": return 590;
			case "BracketLeft": return 591;
			case "Backslash": return 592;
			case "BracketRight": return 593;
			case "Backquote": return 594;
			case "CapsLock": return 595;
			case "ScrollLock": return 596;
			case "NumLock": return 597;
			case "PrintScreen": return 598;
			case "Pause": return 599;
			case "Numpad0": return 600;
			case "Numpad1": return 601;
			case "Numpad2": return 602;
			case "Numpad3": return 603;
			case "Numpad4": return 604;
			case "Numpad5": return 605;
			case "Numpad6": return 606;
			case "Numpad7": return 607;
			case "Numpad8": return 608;
			case "Numpad9": return 609;
			case "NumpadDecimal": return 610;
			case "NumpadDivide": return 611;
			case "NumpadMultiply": return 612;
			case "NumpadSubtract": return 613;
			case "NumpadAdd": return 614;
			case "NumpadEnter": return 615;
			case "NumpadEqual": return 616;
			case "ControlLeft": return 527;
			case "ShiftLeft": return 528;
			case "AltLeft": return 529;
			case "MetaLeft": return 530;
			case "ControlRight": return 531;
			case "ShiftRight": return 532;
			case "AltRight": return 533;
			case "MetaRight": return 534;
			case "ContextMenu": return 535;
			case "Digit0": return 536;
			case "Digit1": return 537;
			case "Digit2": return 538;
			case "Digit3": return 539;
			case "Digit4": return 540;
			case "Digit5": return 541;
			case "Digit6": return 542;
			case "Digit7": return 543;
			case "Digit8": return 544;
			case "Digit9": return 545;
			case "KeyA": return 546;
			case "KeyB": return 547;
			case "KeyC": return 548;
			case "KeyD": return 549;
			case "KeyE": return 550;
			case "KeyF": return 551;
			case "KeyG": return 552;
			case "KeyH": return 553;
			case "KeyI": return 554;
			case "KeyJ": return 555;
			case "KeyK": return 556;
			case "KeyL": return 557;
			case "KeyM": return 558;
			case "KeyN": return 559;
			case "KeyO": return 560;
			case "KeyP": return 561;
			case "KeyQ": return 562;
			case "KeyR": return 563;
			case "KeyS": return 564;
			case "KeyT": return 565;
			case "KeyU": return 566;
			case "KeyV": return 567;
			case "KeyW": return 568;
			case "KeyX": return 569;
			case "KeyY": return 570;
			case "KeyZ": return 571;
			case "F1": return 572;
			case "F2": return 573;
			case "F3": return 574;
			case "F4": return 575;
			case "F5": return 576;
			case "F6": return 578;
			case "F7": return 579;
			case "F8": return 580;
			case "F9": return 581;
			case "F10": return 582;
			case "F11": return 583;
			case "F12": return 584;
			case "F13": return 585;
			case "F14": return 586;
			case "F15": return 587;
			case "F16": return 588;
			case "F17": return 589;
			case "F18": return 560;
			case "F19": return 561;
			case "F20": return 562;
			case "F21": return 563;
			case "F22": return 564;
			case "F23": return 565;
			case "F24": return 566;
			default: return 0;
		}
	}

	onKeyDownHandler(ke) {
		this.pressedKeyCodes[this.ToImGuiKey(ke)] = true;

		if (ke.key.length === 1 || ke.code === "Space")
			this.inputchars.push(ke.key);
	}

	onKeyUpHandler(ke){
		this.pressedKeyCodes[this.ToImGuiKey(ke)] = false;
	}

	updateContainerRect(canvasrootNode){
		this.containerRect = canvasrootNode.getBoundingClientRect();

		const canvas = this.canvas();
		canvas.receivedVideoWidth = 0;
		canvas.receivedVideoHeight = 0;
	}
}

export class StreamSession {

	#impl = new Impl();

	constructor(source,canvasroot){
		this.#impl.setup(source,canvasroot);
	}

	StartListen(){
		this.#impl.StartListen();
	}

	StopListen(){
		this.#impl.StopListen();
	}
}
