import {AuthToken} from "./auth.js";

const KeyLastWorkload = "_LastWorkload"

export function ClearSelectedApp(){
	window.sessionStorage.removeItem(KeyLastWorkload);
}

export function SelectedApp(){
	return window.sessionStorage.getItem(KeyLastWorkload);
}

function appbutton(fullappname){

	const btn = document.createElement('button');

	const pair = fullappname.split(":")
	let shortname = fullappname
	if (pair.length>2){
		shortname = pair[2];
	}

	btn.dataset.app=fullappname;
	btn.style.width='100%';
	btn.style.color='white';
	btn.classList.add("btn", "btn-outline-secondary", "mb-1");
	btn.innerHTML = shortname;

	btn.addEventListener('click',()=>{
		const eventConnect = new CustomEvent('ConnectStream',{detail: {workload: fullappname}});
		window.sessionStorage.setItem(KeyLastWorkload,fullappname);
		document.dispatchEvent(eventConnect);
	})

	return btn
}

async function fetchApps() {

	const resp = await fetch("/api/apps",{
		method: "GET", 
		headers: {"Authorization": "Bearer "+AuthToken(),}
	})

	const appslist = (await resp.json()).sort()
	const lists  = document.querySelectorAll(".appslist");

	let nsselect = document.createElement('select');
	nsselect.style.width="100%";
	nsselect.style.marginBottom="10px";

	const resetAppsListView=()=>{
		lists.forEach(node=>{
			node.innerHTML = appslist.length==0? '<div class="--sys-message --noapps"><h6>No apps are streaming</h6></div>': '';

			if (appslist.length>0){
				node.appendChild(nsselect);
			}
		})
	}

	resetAppsListView();

	// Extract clusters and namespaces
	const clusters={};
	appslist.forEach((appname)=>{

		let pair = appname.split(":");
		const namespaces = clusters[pair[0]]||{};
		const items = namespaces[pair[1]]||[];
		items.push(appname);
		namespaces[pair[1]]=items;
		clusters[pair[0]]=namespaces;
	})

	let nsoptions = "";

	const preferedNS = window.sessionStorage.getItem('prefered-ns') || '';
	Object.keys(clusters).forEach(cluster=>{
		nsoptions+=`<optgroup label=${cluster}>`
		Object.keys(clusters[cluster]).forEach(k =>{
			const nsId = cluster + ":" + k
			const b64 = btoa(nsId)
			nsoptions+=`<option value=${b64} ${nsId==preferedNS? 'selected': ''}>${k}</option>`
		})
		nsoptions+=`</optgroup>`
	})

	nsselect.innerHTML = nsoptions;

	let openedapp = [];

	try {
		openedapp = JSON.parse(window.sessionStorage.getItem('opened-app')||'[]');
	}catch(Ex){
	}

	const showApps=(appslist=>{

		resetAppsListView();

		lists.forEach(listnode=>{
			appslist.forEach(appname=>{

				const btn = appbutton(appname);

				listnode.appendChild(btn);
			})
		});

	})


	nsselect.addEventListener('change',evt=>{
		console.log('namespace changed',evt);
		const nsname = atob(evt.target.value);

		window.sessionStorage.setItem("prefered-ns", nsname);

		showApps(appslist.filter(el=>el.startsWith(nsname+":")))
	})


	if (preferedNS==''){
		const cs = Object.keys(clusters);
		if (cs.length>0) {
			const ns = Object.keys(clusters[cs[0]]);
			showApps(clusters[cs[0]][ns[0]]);
		}else{
			showApps(appslist);
		}
	}else{
		const nsId = preferedNS.split(":");
		showApps(clusters[nsId[0]][nsId[1]]);
	}
}


export function StartListenApps(){

	fetchApps();

	setInterval(()=>{

		fetchApps();

	},5000)

}
