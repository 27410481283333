export class OutMessage {
	constructor(dest, bytes){
		this.dest=dest;
		this.payload=bytes;
		this.ts=(new Date()).getTime();
	}

	Serialize(){
		return JSON.stringify({c: "OutMessage", dest:this.dest,ts: this.ts, data: arrayBufferToBase64(this.payload)})
	}

	Deserialize(record){
		const jrec = JSON.parse(record)
		if (jrec.c!="OutMessage") {
			throw("Bad record");
		}
		this.dest = jrec.dest;
		this.ts = jrec.ts;
		this.bytes = base64StringToBytes(jrec.data);
	}
}

export class InMessage{
	constructor(src, bytes){
		this.src=src;
		this.bytes=bytes;
		this.ts=(new Date()).getTime();
	}

	Serialize(){
		return JSON.stringify({c: "InMessage", src: this.src ,ts: this.ts, data: arrayBufferToBase64(this.bytes)})
	}
	
	Deserialize(record){
		const jrec = JSON.parse(record)
		if (jrec.c!="InMessage") {
			throw("Bad record");
		}
		this.src = jrec.src;
		this.ts = jrec.ts;
		this.bytes = base64StringToBytes(jrec.data);
	}
}

export class RecordingSession {
	constructor(){
		this.recording = false;
		this.frames = [];
	}

	StartRecording(){
		this.recording = true;
	}

	StopRecording(){
		this.recording = false;
	}

	Save(filename){
		function saveToFile(arrayOfBuffers, filename) {
			// Combine all ArrayBuffers into one
			//
			let fileContent=arrayOfBuffers.map(m=>m.Serialize()).join("\n")
			let combinedBuffer = new Blob([fileContent],{type: "text/plain"});

			// Create a temporary download link
			let link = document.createElement('a');
			link.href = window.URL.createObjectURL(combinedBuffer);
			link.download = filename;

			// Append link to the body, trigger click, and then remove it
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}

		saveToFile(this.frames, filename);
	}

	Clear(){
		this.frames=[];
	}

	Record(frame){
		if (this.recording){
			this.frames.push(frame);
		}
	}
}

// Function to convert Base64 string to ArrayBuffer
function base64StringToBytes(base64String) {
	let binaryString = window.atob(base64String);
	let len = binaryString.length;
	let bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes;
}

// Function to convert ArrayBuffer to Base64
function arrayBufferToBase64(buffer) {
	let binary = '';
	let bytes = new Uint8Array(buffer);
	let len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}

const factoryRecords={
	"InMessage": (record)=>{let m = new InMessage();m.Deserialize(record);return m},
	"OutMessage": (record)=>{let m = new OutMessage();m.Deserialize(record);return m},
}
function FactoryRecordToMessage(record){

	const parsed = JSON.parse(record);
	if (parsed.c in factoryRecords){
		return factoryRecords[parsed.c](record);
	}
	
	return null;
}

export function PlaybackFromFile(callback){

	function readFile(inputElementId) {
		// Create a file input element
		let input = document.createElement('input');
		input.type = 'file';
		input.style.display = 'none'; // Hide the input

		// Append the input to the body
		document.body.appendChild(input);

		// Set up the event listener for file selection
		input.onchange = function(event) {
			let file = event.target.files[0];
			if (file) {
				let reader = new FileReader();

				reader.onload = function() {
					// Process the file content
					processFileContent(reader.result);

					// Clean up: remove the input element
					document.body.removeChild(input);
				};

				reader.readAsText(file);
			} else {
				// Clean up if no file is selected
				document.body.removeChild(input);
			}
		};

		// Trigger the file selection dialog
		input.click();
	}

	// Function to process the file content
	function processFileContent(fileContent) {
		// Split by newline to get Base64 strings
		let messages = fileContent.split('\n').map(FactoryRecordToMessage).filter(c=>c!=null);

		if (typeof callback=='function'){
			messages.forEach(m=>callback(messages, m))
			return;
		}
		messages.forEach(m=>{
			if (m.src){
				const flexBuffRef = toReference(m.bytes.slice(4).buffer);
				const metaData = flexBuffRef.get(0);
				const userInfos = [];

				const userInfoFB = metaData.get("userInfo")
				const packetNumber = Number(metaData.get("packetNumber").intValue());
				const isIDR = metaData.get("isIDRFrame").boolValue()

				const videoWidth = Number(metaData.get("width").intValue());
				const videoHeight = Number(metaData.get("height").intValue());

				console.log(`${m.src}: p(${packetNumber}): ${videoWidth}x${videoHeight} idr:${isIDR} `);
			}
		})

	}

	readFile();
}
