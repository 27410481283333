class VersionNode extends HTMLElement {
	constructor(){
		super()
	}

	connectedCallback() {
		const dom = this.attachShadow({mode: "open"});
		const span = document.createElement('span');
		dom.appendChild(span);

		const fetchVersion = async ()=>{
			const resp = await fetch("/health")
			const j = await resp.json();
			span.innerHTML = j.v;
		};

		fetchVersion();
	}
}

customElements.define("b6-version", VersionNode);
