import HomeContent from "./home.html";
import {Username, Email, IsReadOnly, Logout} from './auth.js';

import {StartListening,StopListening} from "./main.js";

import { EventStreamConnected, EventStreamDisconnected,
	EventStreamConnecting, EventIDRWaiting, EventIDRReceived}
from "./stream-session.js"

import {StartListenApps, ClearSelectedApp} from './apps-list.js';

import {Register as B6Filter} from './b6-filterfrom.js';

import { SetRenderer, GetRenderer } from "./renderer.js"

import './touch-keyboard.js';

const KeyViewOnly = "viewonly";

export async function HandleHome(){

	const approot = document.querySelector("#app");
	approot.innerHTML = HomeContent;

	const logoutbtn = document.querySelector("a#logout");
	const disconnectbtn = document.querySelectorAll(".disconnectbtn");

	const renderers = document.querySelectorAll("#renderersselection a");
	const rendererBtn = document.querySelector('#rendererBtn');

	const btnViewOnly = document.querySelector("#btnViewOnly");

	// hacky solution
	const b6only = document.querySelectorAll("[bolt6only]");
	if (!IsReadOnly()) {

		b6only.forEach(el=>el.style.display=undefined);
	}else{
		b6only.forEach(el=>el.style.display='none');

		window.viewonly=true;
		window.localStorage.setItem(KeyViewOnly, window.viewonly.toString());

		document.dispatchEvent(new Event("ViewOnlyChanged"));
	}

	const updateViewOnly=()=>{
		if (window.viewonly) {
			btnViewOnly.classList.remove("active");
		}else{
			btnViewOnly.classList.add("active");
		}
	}
	document.addEventListener("ViewOnlyChanged",(event)=>{
		updateViewOnly();
	})

	const scaleSlider = document.querySelector("#scaleRange");
	if (scaleSlider){
		const oldval = Number(window.localStorage.getItem("request-video-scale")||"1.0");
		const scales=[0.5,0.75,1.0,1.2,1.5];

		const index = scales.indexOf(oldval);
		if (index>=0) {
			scaleSlider.value=index;

			const label = document.querySelector("#"+scaleSlider.id+"Label");
			if (label){
				label.innerHTML = `Scale (x${oldval})`;
			}
		}

		scaleSlider.addEventListener("change",(event)=>{
			const val = event.target.value;

			if (val<0||val>scales.length){
				return;
			}

			window.localStorage.setItem("request-video-scale",scales[val].toString());

			const label = document.querySelector("#"+event.target.id+"Label");
			if (label){
				label.innerHTML = `Scale (x${scales[val]})`;
			}

			document.dispatchEvent(new CustomEvent("RequestVideoScaleChanged", {detail:{scale: scales[val]}}));
		})
	}

	const _statusNodes = document.querySelectorAll("[b6livestatus]");

	document.addEventListener(EventIDRWaiting,()=>{
		// > CONNECTING 
		_statusNodes.forEach(node=>{
			node.style.display='block';
			node.innerHTML=`
				<svg height="20" width="20" class="blinking">
				<circle cx="10" cy="10" r="5" fill="orange" />
				</svg>
				<span>WAITING</span>
				`;
		})
	})

	document.addEventListener(EventIDRReceived,()=>{
		// > LIVE
		const _statusNodes = document.querySelectorAll("[b6livestatus]");
		_statusNodes.forEach(node=>{
			node.style.display='block';
			node.innerHTML="";
		})
	})

	btnViewOnly.addEventListener("click",()=>{
		window.viewonly=!window.viewonly;
		window.localStorage.setItem(KeyViewOnly, viewonly.toString());

		document.dispatchEvent(new Event("ViewOnlyChanged"));
	})

	document.querySelector("#fs-toggle").addEventListener("click",()=>{
		// Toggle FS 
		if (!document.fullscreenElement && 
			!document.mozFullScreenElement && 
			!document.msFullscreenElement){
			document.querySelector(".videoarea").requestFullscreen();
		}else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	})

	window.viewonly = (window.localStorage.getItem(KeyViewOnly) || "true")=="true";
	updateViewOnly();

	const mapnames={
		'2d': '2D Canvas',
		'webgl': 'Web GL',
		'webgl2': 'Web GL2',
	}

	window.selectedRenderer = GetRenderer();
	const onchangerenderer=(event)=>{
		const item=event.target
		const renderer = item.dataset.renderer;
		rendererBtn.innerHTML = mapnames[renderer];
		window.selectedRenderer = renderer;
		SetRenderer(renderer);
	}

	renderers.forEach(item=>{
		item.addEventListener("click",onchangerenderer);
	})


	logoutbtn.addEventListener("click",()=>{
		Logout()
	})

	const onConnect=()=>{
		document.querySelectorAll("[hide-connected]").forEach(el=>el.style.display="none");
		document.querySelectorAll("[show-connected]").forEach(el=>el.style.display="block");
	}

	const onDisconnect=()=>{
		document.querySelectorAll("[hide-connected]").forEach(el=>el.style.display="block");
		document.querySelectorAll("[show-connected]").forEach(el=>el.style.display="none");
		ClearSelectedApp();
	};

	document.addEventListener('ConnectStream', (event)=>{

		document.querySelectorAll("#active-source-name").forEach(el=>{
			el.innerHTML = "#"+event.detail.workload;
		})

		//		window.localStorage.setItem("LastWorkload", event.workload);
		onConnect();
		StartListening(event.detail.workload, '#canvasroot');
	})

	disconnectbtn.forEach(btn=>{
		btn.addEventListener("click",()=>{
			onDisconnect();
			StopListening();
		});
	});


	const update = ()=>{
		const portrait = document.createElement("b6-viewer");
		portrait.setAttribute("name",Email());
		const userbit = document.querySelector("#user");
		userbit.innerHTML="";
		userbit.appendChild(portrait);
		userbit.innerHTML+=Email();
	}

	window.addEventListener('login',()=>{
		update();
	})

	update();

	StartListenApps();

	B6Filter(approot);
}
