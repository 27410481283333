const matchFn=(node, filter, key)=>{
	if (filter=="" || !filter || !node.dataset) {
		return true;
	}

	const value = node.dataset[key];
	if (!value) {
		return true;
	}

	return value.toLocaleLowerCase().indexOf(filter)>=0
}

function B6FilterFrom(node){
	const filterkey = node.dataset["b6Filterkey"];
	const input = document.querySelector(node.dataset["b6Filterfrom"]);


	input.addEventListener("keyup",()=>{
		const lowercaseValue = input.value.toLocaleLowerCase();
		node.childNodes.forEach(record=>{
			if (record.nodeType==3) return;

			if (matchFn(record, lowercaseValue, filterkey)) {
				record.style.display='block';
			}else{
				record.style.display='none';
			}
		})
	})
}

export function Register(root) {

	if (!root) {
		root = document;
	}

	root.querySelectorAll("[data-b6-filterfrom]").forEach((domElement)=>{
		B6FilterFrom(domElement)
	})
}
