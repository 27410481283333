// import donkey from './flatbuffers/flexbuffers.js';

import { worker, EventReloadWorker } from "./worker-service.js";
import { StreamSession } from "./stream-session.js"

const reloadworker = document.querySelector("#reloadww");
if (reloadworker){
	reloadworker.onclick=()=>{
		document.dispatchEvent("sys-reload-worker");
	}
}

let LastStreamSession = null;

export function StopListening() {
	if (LastStreamSession!=null) {
		LastStreamSession.StopListen();
		LastStreamSession=null;
	}
}

export function StartListening(source, canvasroot) {
	if (LastStreamSession!=null){
		LastStreamSession.StopListen();
		LastStreamSession=null;
	}

	LastStreamSession = new StreamSession(source, canvasroot);
	LastStreamSession.StartListen();
}
