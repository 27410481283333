/**
 * TouchInput should show keyboard on touch devices without showing any input fields
 */
class TouchInput extends HTMLElement {
	constructor(){
		super();
	}

	connectedCallback(){
		const button = document.querySelector("button[inviskeyboard]")
		var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
		if (!isTouch) {
			// HACK: we want to hide it away if it's not touch
			button.parentElement.parentElement.style.visibility = 'hidden';
		} else if (button){
			button.onclick=()=>{
				if (button.classList.contains("active")) {
					button.classList.remove("active");
					document.getElementById("inviskeyboard").remove();
				} else {
					button.classList.add("active");
					const input = document.createElement('input');
					input.id = "inviskeyboard";
					input.style='position:fixed;top:-100px;'
					input.onkeydown=(event)=>{
						const canvas = document.getElementsByTagName('canvas');
						canvas[0].dispatchEvent(new KeyboardEvent(event.type, {key: event.key, code: event.code}));
						input.value='';
					}

					input.onkeyup=(event)=>{
						const canvas = document.getElementsByTagName('canvas');
						canvas[0].dispatchEvent(new KeyboardEvent(event.type, {key: event.key, code: event.code}))
					}

					document.body.appendChild(input);
					input.focus();
				}
			}
		}
	}
}

customElements.define('webremote-keyboard',TouchInput);
