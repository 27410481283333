
/* Pointers */


	// Fixed array of colors
	const colors = [
		'#FFADAD', '#FFD6A5', '#FDFFB6', '#CAFFBF', '#9BF6FF',
		'#A0C4FF', '#BDB2FF', '#FFC6FF', '#FFAFCC', '#FFC3A0'
	];

export class Pointers{
	constructor(){
		this.pointersMap = {};
		this.colorIndex=0;
	}

	/**
	* Changes visibility across existing user representations
	* @param {Array} idsArray - list of users whos representation should stay on screen
	*/
	KeepVisible(idsArray) {
		const stringed = idsArray.map(el=>el.toString());
		Object.keys(this.pointersMap).filter(key=>!stringed.includes(key.toString()))
			.forEach(key=>this.pointersMap[key].style.display='none')
	}

	// Function to create/return a pointer
	CreatePointer(id, name) {
		if (this.pointersMap[id]) {
			const pointer = this.pointersMap[id];
			pointer.style.display='block';
			// If pointer already exists, just return it
			return pointer;
		}

		const pointerDiv = document.createElement('div');
		pointerDiv.className = 'pointer';
		pointerDiv.style.display = 'inline-block';
		pointerDiv.style.width = '20px';
		pointerDiv.style.height = '20px';

		pointerDiv.innerHTML=`<svg width="20" height="20" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
			<path d="M0,0 L30,30 L10,25 L0,35 L0,0" fill="${colors[this.colorIndex%colors.length]}"/>
			</svg>
			`
		//    pointerDiv.style.backgroundColor = colors[colorIndex % colors.length]; // Use modulo to loop back to the start when we run out of colors

		this.colorIndex++;

		pointerDiv.style.borderRadius = '50%';

		const nameDiv = document.createElement('div');
		nameDiv.textContent = name;
		nameDiv.style.display = 'inline-block';
		nameDiv.style.marginLeft = '5px';
		nameDiv.style['user-select']='none';

		const container = document.createElement('div');
		container.appendChild(pointerDiv);
		container.appendChild(nameDiv);
		container.style.position="fixed";
		container.style.display='block';
		container.style.zIndex=1000;	// to be on top of canvas, in case it was created and canvas was recreated for another stream later
		container.style.color=pointerDiv.style.backgroundColor;
		container.style.color=colors[this.colorIndex % colors.length];
		container.style["pointer-events"] = "none";

		this.pointersMap[id] = container;

		document.querySelector("#canvasroot").appendChild(container);

		return container;
	}

	// Function to hide/show pointer
	togglePointer(id, action) {
		if (this.pointersMap[id]) {
			this.pointersMap[id].style.display = action === 'show' ? 'block' : 'none';
		}
	}



	ClearAll(){
		for (let key of Object.keys(this.pointersMap)){
			const element = this.pointersMap[key];
			if (element && element.parentNode){
				element.parentNode.removeChild(element);
			}
			delete this.pointersMap[key];
		}
	}

	// Function to set location for the pointer
	SetPointerLocation(id, x, y,canvasClientRect,canvasScale) {
		if (this.pointersMap[id]) {

			let r = canvasClientRect;
			if (!r) {
				r = this.pointersMap[id].parentNode.getBoundingClientRect();
			}
			let scaleX = 1;
			let scaleY = 1;
			if (canvasScale) {
				scaleX = canvasScale;
				scaleY = canvasScale;
			}
			this.pointersMap[id].style.left = x*scaleX + r.x + 'px';
			this.pointersMap[id].style.top = y*scaleY + r.y + 'px';
		}
	}

}
