const tokenKey = "_tokenresponse_";
const redirecturl=() => window.location.origin+"/callback";

function JSON_to_URLEncoded(element,key,list){
  var list = list || [];
  if(typeof(element)=='object'){
    for (var idx in element)
      JSON_to_URLEncoded(element[idx],key?key+'['+idx+']':idx,list);
  } else {
    list.push(key+'='+encodeURIComponent(element));
  }
  return list.join('&');
}

function getCookie(name) {
	var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
	return r ? r[1] : null;
}

export async function HandleCallback() {
	console.log('perform auth with query',window.location.search)

	const searchParams = new URLSearchParams(window.location.search);
	const code = searchParams.get("code");

	const clientid = getCookie("COGNITO_CLIENT_ID");
	const cognitoauthdomain = getCookie("COGNITO_AUTH_DOMAIN");

	const payload = {
			grant_type: "authorization_code",
			client_id: clientid,
			redirect_uri: redirecturl(),
			code: code,
		}

	const urlencoded = JSON_to_URLEncoded(payload);

	const tokenendpoint = cognitoauthdomain + `/oauth2/token`;

	const resp = await fetch(tokenendpoint,{
		method: "POST",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		body: urlencoded,
	})
 
	const json = await resp.json()
	console.log(json)

	if ('error' in json ){
		console.error(json['error']);
		window.localStorage.removeItem(tokenKey)
		window.location.href="/";
		return;
	}

	debugger;
	window.localStorage.setItem(tokenKey, JSON.stringify(json));

	const event = new Event("login")
	window.dispatchEvent(event);

	window.history.replaceState({}, "", "/");
}

export function getAuthUser(){
	const tokenresponse = window.localStorage.getItem(tokenKey);
	return tokenresponse;
}

function parsedResp(){

	const tokenresp = getAuthUser();
	if (IsValid(tokenresp)==false){
		return {};
	}

	return JSON.parse(tokenresp);
}

function parsedIDToken(){
	return JSON.parse(atob(parsedResp().id_token.split(".")[1]))
}

function parsedAccessToken(){
	return JSON.parse(atob(parsedResp().access_token.split(".")[1]))
}

export function Email(){
	try {
		const parsed = parsedIDToken();

		return parsed.email;
	}catch( ex ){
	}

	return "";
}

export function IsReadOnly(){
	try {
		const parsed = parsedAccessToken();

		return !(parsed["cognito:groups"].includes("Bolt6") || parsed["cognito:groups"].includes("WebRemoteFullAccess"));
	}catch( ex ){
	}

	return true;
}

export function Username() {
	const parsed = parsedAccessToken();

	return parsed.name || parsed.username;
}

function IsValid(token){
	if (token==null) return false;
	return true;
}

export function AuthToken(){
	const auths = parsedResp();
	if (auths==null) {
		return "";
	}
	return parsedResp().access_token;
}

export function EnsureLogin(){
	const token = getAuthUser();
	if (IsValid(token)==false){
		window.location.href="/login";
		return false;
	}

	return true;
}

export function Logout(){
	window.localStorage.removeItem(tokenKey);
	// TODO: Should be logout call to OIDC Provider
	window.location.href="/";
}
